import React from "react";
import "./HomePage.css";
import personalimg from "./images/personal-image.png";

function Homepage(props) {
  return (
    <>
      <section className="homepage">
        <div className="left-bar">
          <div className="left-text">
            <p>DESIGN</p>
          </div>
        </div>
        <div className="middle-container">
          <div className="text-container">
            <p className="name">BIPIN</p>
            <p className="second-name">SUBEDI</p>
          </div>
          <div className="pm-container">
            {" "}
            <div className="image-container">
              <img src={personalimg} alt="" />
            </div>
          </div>
        </div>

        <div className="right-bar">
          <div className="right-text">
            <p>CODE</p>
          </div>
        </div>
      </section>
    </>
  );
}
export default Homepage;
