import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

function Contact(props) {
  const form = useRef();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    // Spam protection: Check if email has already been sent within the last 2 minutes
    if (isEmailSent) {
      console.log("Please wait before sending another email.");
      return;
    }

    emailjs
      .sendForm("service_7l601rm", "template_bx5ftbi", form.current, {
        publicKey: "cinrza-JW55XSpf6N",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsEmailSent(true);
          setTimeout(() => {
            setIsEmailSent(false);
          }, 120000); // Reset email sending after 2 minutes
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="footer-main-container">
      <footer>
        <div className="footer-container">
          <div className="footer-contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <div className="left-label">
                <label>Name</label>
                <input type="text" name="user_name" />
                <label>Email</label>
                <input type="email" name="user_email" />
              </div>

              <div className="right-label">
                <label>Message</label>
                <textarea name="message" />
                <input type="submit" value="Send" />
              </div>
            </form>
          </div>

          <div className="footer-logo-container">
            <img
              src={require("../component/images/amongus.png")}
              alt=""
              id="sussybaka"
            />
            <div className="Social-container">
              Let's{" "}
              <a href="#navbar" className="Connect-btn" role="button">
                <span className="connect-btn-icon">
                  <lord-icon
                    src="https://cdn.lordicon.com/fdxqrdfe.json"
                    colors="primary:#1E1E1E"
                    trigger="loop"
                    delay="1000"
                  ></lord-icon>
                </span>
                <span className="text">Connect</span>
                <span>MY socials</span>
              </a>{" "}
            </div>
            {/* <img src={require("./images/contact-logo.png")} alt="" /> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
